import logo from "./logo.svg";
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import "./App.css";
import PineAppBar from "./components/appbar";
import { HomeButtons } from "./components/homebuttons";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import { Agroinsumos } from "./components/agroinsumos";
import Agroinsumo from "./components/agroinsumo";
import Plagas from "./components/plagas";
import Plaga from "./components/plaga";
import Bioinsumo from "./components/bioinsumo";
import { Bioinsumos } from "./components/bioinsumos";
import { plagasData } from "./data/plagasData";
import { agroinsumosData } from "./data/agroinsumosData";
import { bioinsumosData } from "./data/bioinsumosData";

import { Plugins } from "@capacitor/core";
import React, { useEffect, useState } from "react";
import FloatingActionButtons from "./components/botonhome";
import ScrollToTop from "./components/scrolltotop";

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
  },
});

const { SplashScreen } = Plugins;

SplashScreen.show({
  /* showDuration: 2000, */
  autoHide: false,
});

const { Storage } = Plugins;
function App() {
  const [plagaData, setplagaData] = useState([]);
  const [agroData, setagroData] = useState([]);
  const [bioData, setbioData] = useState([]);

  const [paisData, setpaisData] = useState("Costa Rica");
  const [cultivoData, setcultivoData] = useState(["Rep. Dominicana"]);

  useEffect(() => {
    async function fetchData() {
      await plagasData(paisData);
      const result = await Storage.get({ key: "plagas" });
      setplagaData(JSON.parse(result.value));

      await agroinsumosData(paisData);
      const result2 = await Storage.get({ key: "agroinsumos" });
      setagroData(JSON.parse(result2.value));

      await bioinsumosData(paisData);
      const result3 = await Storage.get({ key: "bioinsumos" });
      setbioData(JSON.parse(result3.value));
    }
    fetchData();
  }, [paisData]);

  const searchData = plagaData.concat(agroData).concat(bioData);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <header>
          <PineAppBar agroData={searchData}></PineAppBar>
        </header>

        <main style={{ height: "100%", maxWidth: "600px", minWidth: "355px" }}>
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path="/agroinsumos"
              render={(props) => (
                <>
                  <FloatingActionButtons />
                  <Agroinsumos {...props} agroData={agroData} />
                </>
              )}
            ></Route>

            <Route
              exact
              path="/bioinsumos"
              render={(props) => (
                <>
                  <FloatingActionButtons />{" "}
                  <Bioinsumos {...props} bioData={bioData} />
                </>
              )}
            ></Route>

            <Route
              path="/plagas"
              render={(props) => (
                <>
                  <FloatingActionButtons />
                  <Plagas {...props} plagasData={plagaData} />
                </>
              )}
            ></Route>

            <Route
              path="/agroinsumo/:id"
              render={(props) => (
                <>
                  <FloatingActionButtons />
                  <Agroinsumo />
                </>
              )}
            ></Route>
            <Route
              path="/plaga/:id"
              render={(props) => (
                <>
                  <FloatingActionButtons />
                  <Plaga />
                </>
              )}
            ></Route>
            <Route
              path="/bioinsumo/:id"
              render={(props) => (
                <>
                  <FloatingActionButtons />
                  <Bioinsumo />
                </>
              )}
            ></Route>

            <Route exact path="/">
              <HomeButtons pais={paisData} setpais={setpaisData} />
            </Route>
          </Switch>
        </main>
      </Router>
    </ThemeProvider>
  );
}

export default App;
