import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { agroinsumosData } from "../data/agroinsumosData";
import { Plugins } from "@capacitor/core";

import { Link as RouterLink } from "react-router-dom";
const { Storage } = Plugins;
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    backgroundColor: "#ffff",
  },
}));

export const Agroinsumos = (props) => {
  const agroData = props.agroData;
  const classes = useStyles();
  if (agroData) {
    return (
      <div>
        <List className={classes.list}>
          {agroData.map((data, key) => {
            return (
              <div key={key}>
                <ListItem
                  button
                  component={RouterLink}
                  to={{ pathname: `/agroinsumo/${data.id}` }}
                >
                  <ListItemText
                    primary={data.nombre}
                    secondary={
                      data.ingredientes &&
                      data.ingredientes[0] &&
                      data.ingredientes[0].tipoIngrediente
                    }
                  />
                </ListItem>
                <Divider light />
              </div>
            );
          })}
        </List>
      </div>
    );
  } else {
    return null;
  }
};
