import axios from "axios";
import { Plugins } from "@capacitor/core";

export const plagasData = async (paisData) => {
  const { Storage } = Plugins;

  return await axios
    .get(
      "https://admin.frutaapp.com/plagas-enfermedades?_limit=300&_sort=nombre:ASC&paises.nombre=" +
        paisData
    )
    .then((res) => {
      const result = res.data;
      if (result && result.length !== 0) {
        Storage.set({ key: "plagas", value: JSON.stringify(result) });

        return result;
      }
      return [];
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};
