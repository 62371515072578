import React from "react";
import {
  createMuiTheme,
  withStyles,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

import { green, purple } from "@material-ui/core/colors";

import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { Link as RouterLink } from "react-router-dom";
import bgLogo from "../images/bg.jpg";
import logoNV from "../images/logonicoverde.png";

const HomeSelects = withStyles({
  root: {
    borderRadius: "10px",

    border: "1px solid",

    backgroundColor: "#77D353",
    borderColor: "#ffffff",
    "&:focus": {
      borderRadius: "10px",

      border: "1px solid",

      backgroundColor: "#77D353",
      borderColor: "#ffffff",
    },
  },
})(Select);

const AgoinsumosButton = withStyles({
  root: {
    height: "80px",
    borderRadius: "10px",
    textTransform: "none",
    fontSize: 20,
    padding: "16px 22px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#FECC5D",
    borderColor: "#ffffff",
  },
})(Button);

const BioinsumospButton = withStyles({
  root: {
    height: "80px",

    borderRadius: "10px",

    textTransform: "none",
    fontSize: 26,
    padding: "16px 22px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#77D353",
    borderColor: "#ffffff",
  },
})(Button);

const PlagasButton = withStyles({
  root: {
    height: "80px",
    borderRadius: "10px",
    textTransform: "none",
    fontSize: 20,
    padding: "16px 22px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#F95F62",
    borderColor: "#ffffff",
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  icon: { fontSize: "30px" },
  divBotones: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    backgroundImage: `url(${bgLogo})`,
    backgroundPosition: "bottom",

    backgroundSize: "cover",
    height: "100%",
  },
  divSelect: {
    display: "flex",
    gap: "4%",
    flexDirection: "row",
    paddingBottom: "20px",
  },
  margin: {
    marginBottom: "15px",

    color: "white",
  },
  subtitulo: { fontSize: 16 },
  textohome: { fontSize: 14, color: "lightgray" },
  logoNV: { width: "100%", paddingBottom: "1em" },
  footerHome: {
    padding: "20px",
    backgroundColor: "#3c3c3c8f",
    marginTop: "2em",
    borderRadius: "10px",
  },
  titulo: { fontSize: "25px" },
  label: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formcontrol: { width: "48%" },
  /*   selectLabel: { fontSize: "2.142857rem" }, */
}));

export function HomeButtons(props) {
  /*   console.log("kljlkj " + props.setpais); */
  const handleChange = (event) => {
    props.setpais(event.target.value);
  };
  const classes = useStyles();

  return (
    <div className={classes.divBotones}>
      {/*       <Typography className={classes.titulo}>Seleccione una opción</Typography>
      <Typography variant="subtitle2" style={{ padding: "0 0 20px 10px" }}>
        para monstrar productos certificados
      </Typography> */}
      <div className={classes.divSelect}>
        <FormControl className={classes.formcontrol} variant="filled">
          <InputLabel
            id="demo-simple-select-label"
            className={classes.selectLabel}
          >
            País
          </InputLabel>
          <HomeSelects
            labelId="demo-simple-select-label"
            id="demo-simple-select" /* value={age} */
            label="Age"
            value={props.pais}
            onChange={handleChange}
            /* onChange={() => props.setpais("value")} */
          >
            <MenuItem value={"Costa Rica"}>
              <ListItemAvatar>
                <Avatar
                  alt="Costa Rica"
                  src="https://admin.frutaapp.com/uploads/Costa_Rica_66364a68df.gif"
                />
              </ListItemAvatar>
              <ListItemText>Costa Rica</ListItemText>
            </MenuItem>
            <MenuItem value={"República Dominicana"}>
              <ListItemAvatar>
                <Avatar
                  alt="Rep. Dominicana"
                  src="https://admin.frutaapp.com/uploads/thumbnail_Flag_of_the_Dominican_Republic_svg_5b72efe8a8.png"
                />
              </ListItemAvatar>
              <ListItemText>Rep. Dominicana</ListItemText>
            </MenuItem>
          </HomeSelects>
        </FormControl>
        <FormControl className={classes.formcontrol} variant="filled">
          <InputLabel id="demo-simple-select-label">Cultivo</InputLabel>
          <HomeSelects
            value={props.pais}
            label="Age" /* onChange={handleChange} */
            /* value={age} */
          >
            <MenuItem value={"Aguacate"}>
              <ListItemAvatar>
                <Avatar
                  alt="Aguacate"
                  src="https://admin.frutaapp.com/uploads/20_avocado_706fa5b115.png"
                />
              </ListItemAvatar>
              <ListItemText>Aguacate</ListItemText>
            </MenuItem>
            <MenuItem value={"República Dominicana"}>
              <ListItemAvatar>
                <Avatar
                  alt="Banana"
                  src="https://admin.frutaapp.com/uploads/15_banana_d1f38deecb.png"
                />
              </ListItemAvatar>
              <ListItemText>Banana</ListItemText>
            </MenuItem>
            <MenuItem value={"Costa Rica"}>
              <ListItemAvatar>
                <Avatar
                  alt="Piña"
                  src="https://admin.frutaapp.com/uploads/17_pineapple_bc119cf286.png"
                />
              </ListItemAvatar>
              <ListItemText>Piña</ListItemText>
            </MenuItem>
          </HomeSelects>
        </FormControl>
      </div>
      <AgoinsumosButton
        variant="contained"
        sx={{ m: 1, backgroundColor: "#F95F62" }}
        className={classes.margin}
        component={RouterLink}
        to="/agroinsumos"
      >
        <div className={classes.label}>
          <div className={classes.titulo}>AGROQUÍMICOS</div>
          <div className={classes.subtitulo}>Y ALTERNATIVAS BIOLÓGICAS</div>
        </div>
      </AgoinsumosButton>
      <BioinsumospButton
        variant="contained"
        className={classes.margin}
        component={RouterLink}
        to="/bioinsumos"
      >
        BIOINSUMOS
      </BioinsumospButton>
      <PlagasButton
        variant="contained"
        className={classes.margin}
        component={RouterLink}
        to="/plagas"
      >
        <div className={classes.label}>
          <div className={classes.titulo}>PLAGAS</div>
          <div className={classes.subtitulo}>ENFERMEDADES Y MALEZAS</div>
        </div>
      </PlagasButton>
      <div className={classes.footerHome}>
        <img src={logoNV} className={classes.logoNV} alt="Nicoverde" />
        <Typography className={classes.textohome}>
          <b>FrutaApp</b> ha sido desarrollada para Nicoverde y los productores
          de piña de Costa Rica y de banano y piña de República Dominicana con
          el apoyo de la Cooperación Alemana para el Desarrollo, GIZ, a través
          del programa Del Campo al Plato, implementado por encargo de la
          iniciativa Climática Internacional (IKI) del Ministerio Federal de
          Medio Ambiente, Protección de la Naturaleza, Seguridad Nuclear y
          Protección al Consumidor (BMUV), y del Programa Ideas para una
          Recuperación Verde, implementado por encargo del Ministerio Federal de
          Cooperación Económica y Desarrollo (BMZ).
        </Typography>
      </div>
    </div>
  );
}
