import axios from "axios";
import { Plugins } from "@capacitor/core";

export const agroinsumosData = async (paisData) => {
  /* console.log("pasi " + paisData); */
  const { Storage } = Plugins;
  /*   console.log(
    "https://admin.frutaapp.com//marcas?_sort=nombre:ASC&_limit=300&paises.nombre=" +
      paisData
  ); */
  return await axios

    .get(
      "https://admin.frutaapp.com/marcas?_sort=nombre:ASC&_limit=800&paises.nombre=" +
        paisData
    )
    .then((res) => {
      const result = res.data;
      if (result && result.length !== 0) {
        Storage.set({ key: "agroinsumos", value: JSON.stringify(result) });

        /*   console.log(result); */
        return result;
      }
      return [];
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};
