import React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Agroinsumos } from "./agroinsumos.js";
import { plagasData } from "../data/plagasData";
import CardMedia from "@material-ui/core/CardMedia";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { Bioinsumos } from "../components/bioinsumos";
import { Plugins } from "@capacitor/core";

const useStyles = makeStyles((theme) => ({
  alertas: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    paddingBottom: "30px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 180,
  },
}));

function GetData(plagaData) {
  let { id } = useParams();

  let dataaa = plagaData.find((item) => item.id == id);

  return dataaa;
}
const { Storage } = Plugins;

export default function Plaga(props) {
  const [plagaData, setplagaData] = useState([]);
  useEffect(async () => {
    /* await plagasData(); */

    const result = await Storage.get({ key: "plagas" });
    setplagaData(JSON.parse(result.value));
  }, []);

  const classes = useStyles();
  var agroData = GetData(plagaData);
  //console.log(agroData.bioinsumos);
  return (
    <Card className={classes.root}>
      {agroData && agroData.foto && (
        <CardMedia
          className={classes.media}
          image={"https://admin.frutaapp.com" + agroData.foto.url}
        />
      )}
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          children={agroData && agroData.tipo}
        />
        <Typography
          variant="h4"
          component="h2"
          children={agroData && agroData.nombre}
        ></Typography>
        <Typography
          className={classes.pos}
          color="textSecondary"
          children={
            agroData &&
            agroData.cicloVida &&
            "Ciclo de Vida: " + agroData.cicloVida
          }
        />
        <div className={classes.alertas}>
          {agroData && agroData.comportamiento && (
            <Alert severity="info" variant="outlined">
              <AlertTitle>Comportamiento</AlertTitle>
              {agroData.comportamiento}
            </Alert>
          )}
          {agroData && agroData.tips && (
            <Alert severity="info" variant="outlined">
              <AlertTitle>Recomendaciones</AlertTitle>
              {agroData.tips}
            </Alert>
          )}
        </div>
        {agroData && agroData.marcas && (
          <>
            <Typography variant="h4" component="h2">
              Agroquímicos
            </Typography>
            <div className={classes.alertas}>
              <Agroinsumos agroData={agroData && agroData.marcas} />
            </div>
            {agroData && agroData.bioinsumos[0] && (
              <div>
                <Typography variant="h4" component="h2">
                  Alternativa Biológica
                </Typography>

                <Bioinsumos bioData={agroData && agroData.bioinsumos} />
              </div>
            )}
          </>
        )}
      </CardContent>

      <CardActions>
        <Button component={RouterLink} to="/plagas" size="large">
          Volver
        </Button>
      </CardActions>
    </Card>
  );
}
