import { useEffect, useState } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Link as RouterLink, useParams } from "react-router-dom";
import Plagas from "./plagas";
import { Alertas } from "./alertas.js";
import { agroinsumosData } from "../data/agroinsumosData";
import { Plugins } from "@capacitor/core";
import { Bioinsumos } from "../components/bioinsumos";

const useStyles = makeStyles((theme) => ({
  alertas: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
    paddingBottom: "30px",
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  plagas: {
    marginBottom: 25,
  },
  avatar: { width: theme.spacing(3), height: theme.spacing(3) },
}));

function GetData(data) {
  let { id } = useParams();

  let dataaa = data.find((item) => item.id == id);

  return dataaa;
}
const { Storage } = Plugins;
export default function Agroinsumo() {
  const [data, setData] = useState([]);
  useEffect(async () => {
    /* await agroinsumosData(); */

    const result = await Storage.get({ key: "agroinsumos" });
    //Storage.get({ key: "agroinsumos" });
    setData(JSON.parse(result.value));
  }, []);

  const classes = useStyles();

  var agroData = GetData(data);
  /* console.log(agroData); */
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          children={agroData && agroData.ingredientes[0].tipoIngrediente}
        />
        <Typography
          variant="h4"
          component="h2"
          children={agroData && agroData.nombre}
        ></Typography>
        <AvatarGroup>
          <Avatar
            className={classes.avatar}
            src={
              agroData &&
              "https://admin.frutaapp.com" +
                agroData.cultivos[0].icono.formats.thumbnail.url
            }
          ></Avatar>

          <Avatar
            className={classes.avatar}
            src={
              agroData &&
              "https://admin.frutaapp.com" + agroData.paises[0].bandera.url
            }
          ></Avatar>
        </AvatarGroup>
        <Typography
          className={classes.pos}
          color="textSecondary"
          children={`Ingrediente Activo: ${
            agroData && agroData.ingredientes[0].nombre
          }`}
        />
        <div className={classes.alertas}>
          <Alertas agroData={agroData && agroData} />
        </div>
        {agroData && agroData.plagas[0] && (
          <div className={classes.plagas}>
            <Typography variant="h4" component="h2">
              Plagas
            </Typography>
            <Plagas plagasData={agroData && agroData.plagas} />
          </div>
        )}
        {agroData && agroData.bioinsumos[0] && (
          <div>
            <Typography variant="h4" component="h2">
              Alternativa Biológica
            </Typography>

            <Bioinsumos bioData={agroData && agroData.bioinsumos} />
          </div>
        )}
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to="/agroinsumos" size="large">
          Volver
        </Button>
      </CardActions>
    </Card>
  );
}
