import axios from "axios";
import { Plugins } from "@capacitor/core";

export const bioinsumosData = async (paisData) => {
  const { Storage } = Plugins;

  return await axios
    .get(
      "https://admin.frutaapp.com/bioinsumos?_sort=nombre:ASC&paises.nombre=" +
        paisData
    )
    .then((res) => {
      const result = res.data;
      if (result && result.length !== 0) {
        Storage.set({ key: "bioinsumos", value: JSON.stringify(result) });

        //console.log(result)
        return result;
      }
      return [];
    })
    .catch((error) => {
      console.error(error);
      return [];
    });
};
